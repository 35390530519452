import React from 'react'
import { Helmet } from 'react-helmet'
import { Button } from '../../components/Button'
import { BodyText1, Heading5 } from '../../components/Heading'

import { Layout } from '../../components/Layout'
import { Link } from '../../components/Link'

import './index.scss'

// markup
export const NotFoundPage: React.FC<{}> = () => {
  return (
    <Layout className="page-404" showContact={false}>
      <Helmet>
        <title>404</title>
      </Helmet>
      <div className="content">
        <div className="bg"></div>
        <Heading5>我们似乎找不到您要查找的页面</Heading5>
        <Link to="/">
          <Button border color="black">
            <BodyText1>回到首页</BodyText1>
          </Button>
        </Link>
      </div>
    </Layout>
  )
}

export default NotFoundPage
