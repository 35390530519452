import React from 'react'
import { motion, HTMLMotionProps } from 'framer-motion'
import { CircleArrowIcon, NavArrowIcon } from '../Icon'

import './index.scss'

interface ButtonProps extends HTMLMotionProps<'div'> {
  border?: boolean
  open?: boolean
}

export interface CircleButtonProps extends ButtonProps {
  direction?: 'top' | 'down' | 'left' | 'right'
  color?: 'black' | 'violet'
}

export const CircleButton: React.FC<CircleButtonProps> = ({
  direction = 'top',
  color = 'black',
  ...rest
}) => {
  return (
    <motion.div className={`circle-button ${color}`} {...rest}>
      <CircleArrowIcon direction={direction} color={color} />
    </motion.div>
  )
}

export const Button: React.FC<ButtonProps> = ({
  children,
  className = '',
  border = false,
  open = undefined,
  color,
  ...rest
}) => {
  return (
    <motion.div
      className={`button ${className} ${color} ${border ? 'border' : ''} ${
        open === undefined ? '' : 'arrow'
      }`}
      {...rest}>
      {children}
      {open !== undefined && (
        <NavArrowIcon direction={open ? 'top' : 'down'}></NavArrowIcon>
      )}
    </motion.div>
  )
}
